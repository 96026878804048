import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProducteurPage = () => {
  const data = useStaticQuery(graphql`
    query {
      rondeau: file(relativePath: { eq: "producteur/ferme-rondeau.webp" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      they: file(relativePath: { eq: "producteur/vue-they.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      afsame: file(relativePath: { eq: "producteur/afsame.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="L'association Agro Bio Conso" />
      <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-2">
              Les producteurs et associations partenaires
            </h1>
          </div>
        </div>
      </section>
      <section className="pattern-cross-dots pb-5 pt-5">
        {/*todo : rendre dynamique */}
        <div className="container" style={{padding: "0 1rem"}}>
          <div className="columns is-vcentered is-multiline">
            {/*<div className="column is-6">
              <Link to="/producteur/rondeau" className="box">
                <div className="columns is-vcentered">
                  <div className="column is-narrow has-text-centered is-hidden-tablet-only is-hidden-mobile">
                    <Img fluid={data.rondeau.childImageSharp.fluid} alt="Ferme du Rondeau" />
                    <span className="button mt-5 is-info">Voir le producteur</span>
                  </div>
                  <div className="column">
                    <h2 className="is-size-2 almond">La ferme du Rondeau</h2>
                    <p>Une exploitation agricole familiale de plus de 10 hectares à Lavans-Vuillafans dans le Doubs. Avec un élevage de chèvres, vaches et sangliers mais aussi des cultures bio de céréales et de légumes.</p>
                    <p>Nous transformons nous-mêmes notre production dans nos ateliers : fromages, viande, farines, plats cuisinés…</p>
                  </div>
                </div>
              </Link>
            </div>*/}
            <div className="column is-6">
              <Link to="/producteur/fermedethey" className="box">
                <div className="columns is-vcentered">
                  <div className="column is-narrow has-text-centered is-hidden-tablet-only is-hidden-mobile">
                    <Img fluid={data.they.childImageSharp.fluid} alt="Ferme du They" />
                    <span className="button mt-5 is-info">Voir le producteur</span>
                  </div>
                  <div className="column">
                    <h2 className="is-size-2 almond">La ferme bio de They</h2>
                    <p>Ferme en agriculture biologique en Haute-Saône, entre Besançon et Vesoul. Très diversifiée et autonome en énergie.</p>
                    <p>Vente directe des produits de la ferme : farine, pommes de terre, viandes de bœuf veau, porc, charcuterie, œufs et volailles.</p>
                  </div>
                </div>
              </Link>
            </div>
            {/*<div className="column is-6">
              <Link to="/producteur/afsame" className="box">
                <div className="columns is-vcentered">
                  <div className="column is-narrow has-text-centered is-hidden-tablet-only is-hidden-mobile">
                    <Img fluid={data.afsame.childImageSharp.fluid} alt="AFSAME Gy" />
                    <span className="button mt-5 is-info">Voir le producteur</span>
                  </div>
                  <div className="column">
                    <h2 className="is-size-2 almond">AFSAME : Les jardins bio des Monts de Gy</h2>
                    <p>L'entreprise adaptée Les Jardins Bio des Monts de Gy (Haute-Saône) a été créée par l’Association Franco Suisse d’Action Médico Educative (AFSAME).</p>
                    <p>Des travailleurs en situation de handicap et de difficultés sociales œuvrent pour une production maraîchère biologique plein champs et sous serre.</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="column is-6">
              <Link to="/producteur/ptit-bio" className="box">
                <span className="button is-info is-hidden-tablet-only is-hidden-mobile" style={{float: "right"}}>Voir le producteur</span>
                <h2 className="is-size-2 almond">Le cabas de La Planée</h2>
                <p>Association à La Planée dans le Doubs, qui organise la promotion et la distribution des produits de producteurs locaux&nbsp;:</p>
                <div className="has-text-centered">
                  <span className="tag mr-2 mt-2 is-warning">Au moulin de Poirotte</span>
                  <span className="tag mr-2 mt-2 is-warning">Au panier de la ferme Aymonin</span>
                  <span className="tag mr-2 mt-2 is-warning">Fruitière Chapelle des Bois</span>
                  <span className="tag mr-2 mt-2 is-warning">GAEC du Haut Pâturage</span>
                  <span className="tag mr-2 mt-2 is-warning">La Batailleuse</span>
                  <span className="tag mr-2 mt-2 is-warning">La Bonne Bouille</span>
                  <span className="tag mr-2 mt-2 is-warning">La Semilla</span>
                  <span className="tag mr-2 mt-2 is-warning">Le Chaudron du Jura</span>
                  <span className="tag mr-2 mt-2 is-warning">Le Clos des Grives</span>
                  <span className="tag mr-2 mt-2 is-warning">Le fournil du lac</span>
                  <span className="tag mr-2 mt-2 is-warning">Le verger d'Arc-sous-Cicon</span>
                  <span className="tag mr-2 mt-2 is-warning">Les Juju Pasta</span>
                  <span className="tag mr-2 mt-2 is-warning">Les petites plantes</span>
                  <span className="tag mr-2 mt-2 is-warning">Les Ruches de l'Arlier</span>
                  <span className="tag mr-2 mt-2 is-warning">Menthe et Mélisse</span>
                  <span className="tag mr-2 mt-2 is-warning">Mémé dans le bouillon</span>
                </div>
              </Link>
            </div>*/}
          </div>
        </div>
      </section>
    </Layout>);
  }

export default ProducteurPage
